<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>

    <v-dialog v-model="dialog" width="250">
        <v-card>
            <v-card-title class="text-purple" style="font-weight: 300; font-size: 16px">
                {{ selected_row.provider_name }}
                <!-- {{ selected_row.booking_rec_id}} -->
            </v-card-title>
            <v-card-text>
                <v-list-item three-line>
                    <!-- {{currentDate()}}
                {{new Date(selected_row.booking_date)}} -->
                    <v-list-item-content>
                        <v-list-item-title><b>วันที่ : </b>{{ selected_row.booking_date }}</v-list-item-title>
                        <v-list-item-title><b>เวลา : </b>{{ selected_row.booking_time }}</v-list-item-title>
                        <v-list-item-title><b>หมายเหตุ : </b>{{ selected_row.detail }}</v-list-item-title>
                        <v-list-item-title><b>สถานะ : </b>{{ selected_row.status }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>
            <v-card-actions style="    justify-content: space-between;">
                <v-btn @click="delBooking" class="ma-2" x-small outlined fab color="red">
                    <v-icon>delete_forever</v-icon>
                </v-btn>
                &nbsp;
                <v-btn color="bg-purple text-white" @click="dialog = false">ตกลง</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col xl="3" lg="4" sm="8" class="mx-auto">
                <!-- <checkmong-home-bar title="การนัดหมาย"></checkmong-home-bar> -->
                <checkmong-home-bar title="นัดหมอ"></checkmong-home-bar>
                <br />
                <span class="text-purple">รายการนัดหมาย</span>
                <v-divider></v-divider>
                <br />
                <v-row class="d-flex">
                    <v-col cols="12">
                        <v-text-field v-model="search" append-icon="search" label="" single-line hide-details style="padding-top: 0px;margin-top: 0px;background-color: white;border-top-left-radius: 5px;border-top-right-radius: 5px;"  flat></v-text-field>
                        <!-- <v-data-table :search="search" :headers="headers" :items="booking_list" :items-per-page="10" :disable-pagination="true" dense mobile-breakpoint @click:row="handleClick"> -->
                        <v-data-table hide-default-header hide-default-footer :search="search" :disable-pagination="false" :headers="headers" :items="booking_list" :items-per-page="itemsPerPage" @click:row="handleClick" mobile-breakpoint :page.sync="page" @page-count="pageCount = $event">
                            <template v-slot:item.status="{ item }">

                                <v-btn outlined v-if="new Date(item.booking_date).setHours(0,0,0,0)  > new Date().setHours(0,0,0,0)" color="#0D8AAF" x-small style="font-size:10px;">
                                    <span>ยังไม่ถึง</span><br><span>วันนัด</span>
                                </v-btn>

                                <v-btn outlined v-if="new Date(item.booking_date).setHours(0,0,0,0)  == new Date().setHours(0,0,0,0)" color="#A84292" x-small>
                                    ถึงวันนัดแล้ว
                                </v-btn>

                                <v-btn outlined v-if="new Date(item.booking_date).setHours(0,0,0,0)  < new Date().setHours(0,0,0,0)" color="#F5F5F5" x-small>
                                    เสร็จสมบูรณ์
                                </v-btn>

                            </template>

                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- {{selected_row}} -->
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn @click="$router.push('/home_checkmong')" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="$router.push('/book_docter_add_reg_only')" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                <v-icon left> control_point </v-icon>
                เพิ่มการนัดหมาย
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn @click="$router.push('/home_checkmong')" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn @click="$router.push('/book_docter_add_reg_only')" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                    <v-icon left> control_point </v-icon>
                    เพิ่มการนัดหมาย
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
   
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        active_waiting: false,
        title: '',
        status: '',
        search: '',
        attrs: "",
        on: "",
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

        headers: [{
                text: "วันที่",
                value: "booking_date",
            },
            {
                text: "คลินิก/สถานพยาบาล",
                value: "provider_name",
            },
            {
                text: "สถานะ",
                value: "status",
            },

        ],
        booking_list: [],
    }),

    mounted() {
        this.arrayEvents = [...Array(6)].map(() => {
            const day = Math.floor(Math.random() * 30);
            const d = new Date();
            d.setDate(day);
            return d.toISOString().substr(0, 10);
        });
        this.getBookingList()

    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    methods: {
        async delBooking() {
            //console.log(this.selected_row.booking_rec_id)
            this.active_waiting = true
            this.title = 'กำลังลบข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/del_booking_record', {
                "booking_rec_id": this.selected_row.booking_rec_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    this.dialog_edit = false
                    this.dialog = false
                    // this.getBookingList()
                    this.$router.go()

                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },
        currentDate() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
            return date;
        },
        async getBookingList() {
            this.active_waiting = true
            this.title = 'กำลังอัพเดต',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_booking_record', {
                "user_id": this.$store.state.login_detail.user_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.booking_list = response.data.booking_record_list;
                    //console.log('777777777777777')
                    //console.log(this.provider_list)
                } else {
                    this.active_waiting = false
                }

            })

        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        handleClick(value) {
            this.selected_row = value;
            this.dialog = true;
        },
        // getBookingList() {
        //     const booking_list = [{
        //             date: "1/5/2565",
        //             time: "10:00:00",
        //             provider_name: "คลินิกนิตยา",
        //             booking_status: "ยังไม่ถึงวันนัด",
        //             detail: "เริ่มลืมบ่อยขึ้น",
        //         },
        //         {
        //             date: "25/5/2565",
        //             time: "10:00:00",
        //             provider_name: "คลินิกนิตยา",
        //             booking_status: "ถึงวันนัดแล้ว",
        //             detail: "จำลูกไม่ได้",
        //         },
        //         {
        //             date: "25/5/2564",
        //             time: "10:00:00",
        //             provider_name: "คลินิกนิตยา",
        //             booking_status: "เสร็จสมบูรณ์",
        //             detail: "จำลูกไม่ได้",
        //         },

        //     ]
        //     this.booking_list = booking_list
        // }
    },
};
</script>

<style >
.theme--light.v-pagination .v-pagination__item--active {
    color: rgb(0 0 0 / 87%) !important;
}
</style>
